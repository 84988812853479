<template>
  <div class="btn-group hiddenclass1199" v-if="device==='desktop'">
    <button class="btn btn-outline-primary dropdown-toggle" type="button" :class="{'disabled' : filterDisabled}" ref="airportFilter"
     id="dropdownMenuClickableInside" data-bs-toggle="dropdown" data-bs-auto-close="outside" @click="() => showedDropdown()">
      {{(isFiltered) ? fltdBtnText : $t("sabre.search-result.airports")}}
    </button>
    <div class="dropdown-menu rectangledropdown" aria-labelledby="dropdownMenuClickableInside">
      <airline-port-selection v-model="selected" :options="airportList"/>
      <div class="btn-group-area d-flex justify-content-center">
        <button class="cleanup_btn" @click="() => clean()">{{$t("sabre.buttons.clean")}}</button>
        <button class="save_btn" @click="() => save()">{{$t("sabre.buttons.save")}}</button>
      </div>

    </div>
  </div>

  <div class="accordion-item" v-else>
    <h2 class="accordion-header" id="heading3">
      <button class="accordion-button collapsed" type="button" :class="{'disabled' : filterDisabled}"
       data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseThree">
        {{$t("sabre.search-result.airports")}}
      </button>
    </h2>
    <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#accordionExample">
      <div class="accordion-body text-right">
        <airline-port-selection v-model="selected" :options="airportList"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import filterControlMixin from '@/sabre/fo/searchResult/filterPanel/filterControlMixin';

export default {
  mixins: [filterControlMixin],
  props: ['type'],
  components: {
    AirlinePortSelection: () => import('./airlinePortSelection'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
    }),
    airportList() {
      const { filterConditions } = this;
      return filterConditions?.airportList || [];
    },
    isFiltered() {
      return this.filterApplied && (this.selected.length !== this.airportList.length);
    },
    fltdBtnText() {
      const { selected } = this;
      const fltdItemList = selected.length > 0 ? `${selected[0]}...` : '';
      return `+${selected.length} ${fltdItemList}`;
    },
  },
  watch: {
    selected() {
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'selectedAirport', value: this.selected });
    },
    selectedAirport() {
      this.selected = this.selectedAirport;
    },
  },
  data() {
    return {
      selected: [],
      filterApplied: false,
    };
  },
  methods: {
    clean() {
      this.selected = [];
      this.$store.commit('SET_SABRE_FILTER_CONDITIONS', { key: 'selectedAirport', value: this.selected });
    },
    save() {
      window.bootstrap.Dropdown.getOrCreateInstance(this.$refs.airportFilter).hide();
      this.applyFilter();
      this.filterApplied = true;
    },
    showedDropdown() {
      this.filterApplied = false;
    },
  },
};
</script>
